import { AuthPage as AntdAuthPage, AuthProps } from "./antdAuthPage";
import { Link } from "react-router-dom";
import { Button } from "antd";
const authWrapperProps = {
    style: {
        background:
            "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%),url('images/metaverse.png')",
        backgroundSize: "cover",
    },
};

const renderAuthContent = (content: React.ReactNode,
) => {
    return (
        <div
            style={{
                maxWidth: 408,
                margin: "auto",
            }}
        >
            <Link to="/">
                <img
                    style={{ marginBottom: 26 }}
                    src="/images/fine-foods-login.svg"
                    alt="Logo"
                    width="100%"
                />
            </Link>
            {content}
        </div>
    );
};

export const AuthPage: React.FC<any> = ({ type, formProps }) => {
    return (
        <>
            <AntdAuthPage
                type={type}
                wrapperProps={authWrapperProps}
                renderContent={renderAuthContent}
                formProps={formProps}
                contentProps={{
                    // title: "Login",
                    headStyle: {
                        background: "rgba(100, 100, 100, 0.6)",
                        color: "white",
                    },
                    bodyStyle: {
                        background: "rgba(200, 200, 200, 0.1)",
                    },
                    style: {
                        background: "rgba(200, 200, 200, 0.8)",
                        // color:"white"
                    }
                }}
            />
        </>
    );
};
