import { HttpError } from "@refinedev/core";
import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create();

// axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
//     // 在请求头部中添加通用参数
//     if (request.headers) {
//         request.headers['Authorization'] = `Bearer ${123}`;
//     }
//     return request;
// });

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

export { axiosInstance };
