import React from "react";
import { Authenticated, GitHubBanner, Refine, AuthBindings, DataProvider, HttpError } from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import {
    notificationProvider,
    ThemedLayoutV2,
    ErrorComponent,
} from "@refinedev/antd";
import routerProvider, {
    CatchAllNavigate,
    NavigateToResource, 
    UnsavedChangesNotifier,
    DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";

import {
    ShoppingOutlined,
    UsergroupAddOutlined,
    ShopOutlined,
    StarOutlined,
    LineChartOutlined,
    DashboardOutlined,
} from "@ant-design/icons";
// import jsonServerDataProvider from "@refinedev/simple-rest";
import jsonServerDataProvider from "./jsonServerDataProvider";
import createAuthRefreshInterceptor from "axios-auth-refresh";
// import { authProvider } from "authProvider";

import "dayjs/locale/de";

import { DashboardPage } from "./pages/dashboard";
import { OrderList, OrderShow } from "./pages/orders";
import { UserList, UserShow } from "./pages/users";
// import {
//     CourierList,
//     CourierShow,
//     CourierCreate,
//     CourierEdit,
// } from "./pages/couriers";
import { ProductList } from "./pages/products";
import { StoreCreate, StoreEdit, StoreList } from "./pages/stores";
import { CategoryList } from "./pages/categories";
import { ReviewsList } from "./pages/reviews";
import { GameUsersList, GameUsersCreate, GameUsersEdit, GameUsersShow } from "./pages/gameusers";
import { FunctionList, FunctionCreate, FunctionEdit, FunctionShow } from "./pages/function";


import { AuthPage } from "./pages/auth"

import { useTranslation } from "react-i18next";
import { Header, Title, OffLayoutArea } from "components";
import { BikeWhiteIcon, PizzaIcon } from "components/icons";
import { ConfigProvider } from "context";
import "@refinedev/antd/dist/reset.css";
import { message } from "antd";
import axios, { AxiosRequestConfig } from 'axios'



const App: React.FC = () => {
    console.log('-----------process.env', process.env)
    //基地址
    const api = axios.create({
        // baseURL: 'http://localhost:8000',
        // baseURL: 'http://localhost:9000',
        baseURL: 'http://localhost:9001',

        // baseURL: 'https://api-meta.zoomsh.cn/huanghe/api'
        // baseURL: 'http://jifei.zoomsh.cn/api/new'
        // baseURL: "https://api.finefoods.refine.dev"

    })
    // const API_URL = "http://localhost:8000";
    // const API_URL = "http://localhost:9000";
    // const API_URL = "https://api.finefoods.refine.dev";
    // const API_URL = 'https://api-meta.zoomsh.cn/huanghe/api'
    const API_URL = 'http://localhost:9001'
    // http://jifei.zoomsh.cn/api/new/user/login

    api.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    // const dataProvider = jsonServerDataProvider(API_URL);

    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };
    const authProvider: AuthBindings = {
        register: async ({ email, password, providerName }) => {
            // const result = await api.post("/auth/signup", { name: email, password: password })
            const result = await api.post("admin/auth/register", {
                email: '1832142380@qq.com',
                username: "testusername2",
                password: 'asdasdasdasd'
            })

            return {
                success: false,
            };
        },
        login: async (params) => {
            const { email, password, username, userId } = params
            const result = await api.post("/admin/auth/login", { password: password, username: username })
            console.log(result)
            if (result.data.token) {
                localStorage.setItem("token", result.data.token)
                document.cookie = `name=${decodeURIComponent(result.data.token)}; path=/`

                api.defaults.headers.common = {
                    Authorization: `Bearer ${result.data.token}`,
                };
                return {
                    success: true,
                }
            } else {
                return {
                    success: false,
                }
            }
        },

        logout: async (params) => {
            localStorage.setItem("token", "")
            return {
                success: true,
            }
        },
        check: async () => {
            const token = localStorage.getItem("token")
            console.log('---------token', token)
            if (token) {
                return {
                    authenticated: true,
                }
            } else {
                return {
                    authenticated: false,
                }
            }

        },
        onError: async (error) => {
            return {
                redirectTo: "/gameusers",
                logout: true,
                error: error,
            };
        },
    }
    // --
    return (
        <BrowserRouter>
            <ConfigProvider>
                <RefineKbarProvider>
                    <Refine
                        routerProvider={routerProvider}
                        dataProvider={jsonServerDataProvider(API_URL, api)}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                        notificationProvider={notificationProvider}
                        resources={[
                            {
                                name: "dashboard",
                                list: "/",
                                meta: {
                                    label: t("dashboard.title"),
                                    icon: <DashboardOutlined />,
                                },
                            },
                            {
                                name: "gameusers",
                                list: "/gameusers",
                                create: "/gameusers/create",
                                edit: "/gameusers/edit/:id",
                                show: "/gameusers/show/:id",
                                meta: {
                                    icon: <StarOutlined />,
                                },
                            },
                            {
                                name: "function",
                                list: "/function",
                                create: "/function/create",
                                edit: "/function/edit/:id",
                                show: "/function/show/:id",
                                meta: {
                                    icon: <StarOutlined />,
                                },
                            },
                            {
                                name: "diagram",
                                list: "/",
                                meta: {
                                    label: t("dashboard.diagrams.title"),
                                    icon: <LineChartOutlined />,
                                },
                            },

                            // {
                            //     name: "orders",
                            //     list: "/orders",
                            //     show: "/orders/show/:id",
                            //     meta: {
                            //         icon: <ShoppingOutlined />,
                            //     },
                            // },
                            // {
                            //     name: "users",
                            //     list: "/users",
                            //     show: "/users/show/:id",
                            //     meta: {
                            //         icon: <UsergroupAddOutlined />,
                            //     },
                            // },
                            // {
                            //     name: "products",
                            //     list: "/products",
                            //     meta: {
                            //         icon: <PizzaIcon />,
                            //     },
                            // },
                            // {
                            //     name: "stores",
                            //     list: "/stores",
                            //     create: "/stores/create",
                            //     edit: "/stores/edit/:id",
                            //     meta: {
                            //         icon: <ShopOutlined />,
                            //     },
                            // },
                            // {
                            //     name: "categories",
                            //     list: "/categories",
                            // },
                            // {
                            //     name: "couriers",
                            //     list: "/couriers",
                            //     create: "/couriers/create",
                            //     edit: "/couriers/edit/:id",
                            //     show: "/couriers/show/:id",
                            //     meta: {
                            //         icon: <BikeWhiteIcon />,
                            //     },
                            // },
                            // {
                            //     name: "reviews",
                            //     list: "/reviews",
                            //     meta: {
                            //         icon: <StarOutlined />,
                            //     },
                            // }
                        ]}
                    >
                        <Routes>
                            <Route
                                element={
                                    <Authenticated
                                        fallback={
                                            <CatchAllNavigate to="/login" />
                                        }
                                    >
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={Title}
                                        // OffLayoutArea={OffLayoutArea}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route index element={<DashboardPage />} />
                                {/* 
                                <Route path="/orders">
                                    <Route index element={<OrderList />} />
                                    <Route
                                        path="show/:id"
                                        element={<OrderShow />}
                                    />
                                </Route>

                                <Route path="/users">
                                    <Route index element={<UserList />} />
                                    <Route
                                        path="show/:id"
                                        element={<UserShow />}
                                    />
                                </Route>

                                <Route
                                    path="/products"
                                    element={<ProductList />}
                                />

                                <Route path="/stores">
                                    <Route index element={<StoreList />} />
                                    <Route
                                        path="create"
                                        element={<StoreCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<StoreEdit />}
                                    />
                                </Route>

                                <Route
                                    path="/categories"
                                    element={<CategoryList />}
                                /> */}

                                {/* <Route path="/couriers">
                                    <Route index element={<CourierList />} />
                                    <Route
                                        path="create"
                                        element={<CourierCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<CourierEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<CourierShow />}
                                    />
                                </Route> */}

                                <Route
                                    path="/reviews"
                                    element={<ReviewsList />}
                                />
                                <Route path="/gameusers">
                                    <Route index element={<GameUsersList />} />
                                    <Route
                                        path="create"
                                        element={<GameUsersCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<GameUsersEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<GameUsersShow />}
                                    />
                                </Route>
                                <Route path="/function">
                                    <Route index element={<FunctionList />} />
                                    <Route
                                        path="create"
                                        element={<FunctionCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<FunctionEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<FunctionShow />}
                                    />
                                </Route>

                          

                            </Route>

                            <Route
                                element={
                                    <Authenticated fallback={<Outlet />}>
                                        <NavigateToResource resource="dashboard" />
                                    </Authenticated>
                                }
                            >
                                <Route
                                    path="/login"
                                    element={
                                        <AuthPage
                                            type="login"
                                            formProps={{
                                                initialValues: {
                                                    username: "admin",
                                                    password: "asdasdasdasd",
                                                },
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/login-email"
                                    element={
                                        <AuthPage
                                            type="login"
                                            formProps={{
                                                initialValues: {
                                                    password: "",
                                                },
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/login-mobile"
                                    element={
                                        <AuthPage
                                            type="login"
                                        />
                                    }
                                />

                                <Route
                                    path="/register"
                                    element={
                                        <AuthPage
                                            type="register"
                                            formProps={{
                                                initialValues: {
                                                    email: "demo@refine.dev",
                                                    password: "3",
                                                },
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/forgot-password"
                                    element={<AuthPage type="forgotPassword" />}
                                />
                                <Route
                                    path="/update-password"
                                    element={<AuthPage type="updatePassword" />}
                                />
                            </Route>

                            <Route
                                element={
                                    <Authenticated>
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={Title}
                                        // OffLayoutArea={OffLayoutArea}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                        </Routes>
                        <UnsavedChangesNotifier />
                        <DocumentTitleHandler />
                    </Refine>
                </RefineKbarProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
