import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
} from "@refinedev/core";
import {
    Edit,
    SaveButton,
    getValueFromEvent,
    useStepsForm,
} from "@refinedev/antd";
import { useMemo, useState } from "react";

import {
    Form,
    Select,
    Upload,
    Input,
    Button,
    Steps,
    Typography,
    Space,
    Avatar,
    Row,
    Col,
    InputProps,
    DatePicker
} from "antd";
import InputMask from "react-input-mask";
const { Text } = Typography;
import { IGameusers } from "interfaces";
import dayjs, { Dayjs } from "dayjs";

export const GameUsersEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {
        current,
        gotoStep,
        stepsProps,
        formProps,
        saveButtonProps,
        queryResult,
    } = useStepsForm<IGameusers>();
    console.log('-------------------formProps', formProps)
    // dayjs(formProps.initialValues.birthDate )
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
        dayjs().subtract(7, "days").startOf("day"),
        dayjs().startOf("day"),
    ]);
    const [start, end] = dateRange;

    const query = {
        start,
        end,
    };
    const apiUrl = useApiUrl();

    const initialValues = {
        birthDate: dayjs("2003-07-16")
    };
    const formList = [
        <>
            <Row gutter={20}>
                <Col xs={24} lg={8}>
                    <Form.Item>
                        <Form.Item
                            name="avatar"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${apiUrl}/media/upload`}
                                listType="picture"
                                maxCount={1}
                                multiple
                                style={{
                                    border: "none",
                                    width: "100%",
                                    background: "none",
                                }}
                            >
                                <Space direction="vertical" size={2}>
                                    <Avatar
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            maxWidth: "200px",
                                        }}
                                        src="/images/user-default-img.png"
                                        alt="Store Location"
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 800,
                                            fontSize: "16px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {t(
                                            "gameusers.fields.images.description",
                                        )}
                                    </Text>
                                    <Text style={{ fontSize: "12px" }}>
                                        {t("gameusers.fields.images.validation")}
                                    </Text>
                                </Space>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={16}>
                    <Row gutter={10}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("gameusers.fields.username")}
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input disabled />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.nickname")}
                                name="nickName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={t("gameusers.fields.password")}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.gender.label")}
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: t(
                                                "gameusers.fields.gender.male",
                                            ),
                                            value: "Male",
                                        },
                                        {
                                            label: t(
                                                "gameusers.fields.gender.female",
                                            ),
                                            value: "Female",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("gameusers.fields.gsm")}
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputMask mask="(999) 999 99 99">
                                    {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                                    {(props: InputProps) => (
                                        <Input {...props} />
                                    )}
                                </InputMask>
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.email")}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.birthDate")}
                                name="birthDate"
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format={'YYYY-MM-DD'} />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.firstName")}
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.lastName")}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.platform.label")}
                                name="platformId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: t(
                                                "gameusers.fields.platform.HuangHe",
                                            ),
                                            value: "HuangHe",
                                        },
                                        {
                                            label: t(
                                                "gameusers.fields.platform.Meta",
                                            ),
                                            value: "Meta",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label={t("gameusers.fields.address")}
                        name="address"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label={t("gameusers.fields.isActive.label")}
                        name="isActive"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    label: t(
                                        "gameusers.fields.isActive.active",
                                    ),
                                    value: "Is Active",
                                },
                                {
                                    label: t(
                                        "gameusers.fields.isActive.inactive",
                                    ),
                                    value: "Is Inactive",
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>,

    ];

    return (
        <>
            <Edit
                isLoading={queryResult?.isFetching}
                saveButtonProps={saveButtonProps}
                headerButtons={
                    <>
                        {(
                            <SaveButton
                                style={{ marginRight: 10 }}
                                {...saveButtonProps}
                            />
                        )}
                    </>
                }
            >
                <Form
                    {...formProps}
                    style={{ marginTop: 30 }}
                    layout="vertical"
                    initialValues={{
                        isActive: true,
                        ...formProps.initialValues,
                        birthDate: formProps.initialValues?.birthDate ? dayjs(formProps.initialValues?.birthDate) : ''
                    }}
                >
                    {formList[current]}
                </Form>
            </Edit>
        </>
    );
};
