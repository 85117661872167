import {
    useTranslate,
    IResourceComponentsProps,
    useDelete,
    useNavigation,
    useCustom,
    useApiUrl
} from "@refinedev/core";

import { List, useTable } from "@refinedev/antd";

import {
    EditOutlined,
    CloseCircleOutlined,
    MoreOutlined, 
} from "@ant-design/icons";
import { Table, Button, Avatar, Dropdown, Menu, Space, Typography } from "antd";

import { IGameusers } from "interfaces";
import { Router, useLocation } from "react-router-dom";
export const FunctionList: React.FC<IResourceComponentsProps> = () => {
    const { show, edit } = useNavigation();
    const t = useTranslate();
    const location = useLocation();
    const apiUrl = useApiUrl();
    // const { data, isLoading } = useCustom<{
    //     data: [];
      
    // }>({
    //     url: `${apiUrl}/admin/gameUsers`,
    //     method: "get",
    // });
    // // https://api.finefoods.refine.dev
    // console.log('------data',data)
    const { tableProps } = useTable<IGameusers>({
        resource:`admin/gameUsers`,
        initialSorter: [
            {
                field: "id",
                // order: "desc",
                order: "asc",
            },
        ],
    });

    const { mutate: mutateDelete } = useDelete();

    const handleEdit = (id:number) => {
        edit("gameusers", id);
    }
    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    handleEdit(id)
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="reject"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "gameusers",
                        id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {t("buttons.delete")}
            </Menu.Item>
        </Menu>
    );

    return (
        // <List createButtonProps={{ 
        //     size: "middle" ,
        //     onClick: handleCreateClick
            
        //     }}>
        <List >
            <Table
                // {...data}
                {...tableProps}
                rowKey="id"
                onRow={(record) => {
                    return {
                        onClick: () => {
                            show("gameusers", record._id);
                        },
                    };
                }}
            >
                
                <Table.Column
                    key="name"
                    title={t("gameusers.fields.avatar.label")}
                    render={(record) => (
                        <Space>
                            <Avatar size={74} src={record.avatar?.[0]?.url} />
                            <Typography.Text style={{ wordBreak: "inherit" }}>
                                213{record.username} 
                            </Typography.Text>
                        </Space>
                    )}
                />
                <Table.Column
                    dataIndex="username"
                    title={t("gameusers.fields.username")}
                />
                <Table.Column
                    dataIndex="email"
                    title={t("gameusers.fields.email")}
                />
                <Table.Column
                    dataIndex="gender"
                    title={t("gameusers.fields.gender.label")}
                />
                <Table.Column<IGameusers>
                    fixed="right"
                    title={t("table.actions")}
                    dataIndex="actions"
                    key="actions"
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record._id)}
                            trigger={["click"]}
                        >
                            <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>
        </List>
    );
};
