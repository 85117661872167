export const FineFoodsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
    props,
) => (
    <img
                    style={{ marginBottom: -22 }}
                    src="/images/title.svg"
                    alt="Logo"
                    width="100%"
                />
);
