import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
} from "@refinedev/core";

import {
    Create,
    SaveButton,
    getValueFromEvent,
    useStepsForm,
    useSelect,
} from "@refinedev/antd";

import {
    Form,
    Select,
    Upload,
    Input,
    Button,
    Steps,
    Typography,
    Space,
    Avatar,
    Row,
    Col,
    InputProps,
    DatePicker
} from "antd";

import InputMask from "react-input-mask";
import { useCreate } from "@refinedev/core";
const { Text } = Typography;

import { IGameusers, IStore } from "interfaces";

export const FunctionCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {
        current,
        gotoStep,
        stepsProps,
        formProps,
        saveButtonProps,
        queryResult,
    } = useStepsForm<IGameusers>();
    const apiUrl = useApiUrl();

    const formList = [
        <>
            <Row gutter={20}>
                <Col xs={24} lg={8}>
                    <Form.Item>
                        <Form.Item
                            name="avatar"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${apiUrl}/media/upload`}
                                listType="picture"
                                maxCount={1}
                                multiple
                                style={{
                                    border: "none",
                                    width: "100%",
                                    background: "none",
                                }}
                            >
                                <Space direction="vertical" size={2}>
                                    <Avatar
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            maxWidth: "200px",
                                        }}
                                        src="/images/user-default-img.png"
                                        alt="Store Location"
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 800,
                                            fontSize: "16px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {t(
                                            "gameusers.fields.images.description",
                                        )}
                                    </Text>
                                    <Text style={{ fontSize: "12px" }}>
                                        {t("gameusers.fields.images.validation")}
                                    </Text>
                                </Space>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={16}>
                    <Row gutter={10}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("gameusers.fields.username")}
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.nickname")}
                                name="nickName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.password")}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.gender.label")}
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: t(
                                                "gameusers.fields.gender.male",
                                            ),
                                            value: "Male",
                                        },
                                        {
                                            label: t(
                                                "gameusers.fields.gender.female",
                                            ),
                                            value: "Female",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("gameusers.fields.mobile")}
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputMask mask="(999) 999 99 99">
                                    {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                                    {(props: InputProps) => {
                                        return <Input {...props} />;
                                    }}
                                </InputMask>
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.email")}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.birthDate")}
                                name="birthDate"
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format={'YYYY-MM-DD'} />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.firstName")}
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.lastName")}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("gameusers.fields.platform.label")}
                                name="platformId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: t(
                                                "gameusers.fields.platform.HuangHe",
                                            ),
                                            value: "HuangHe",
                                        },
                                        {
                                            label: t(
                                                "gameusers.fields.platform.Meta",
                                            ),
                                            value: "Meta",
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label={t("gameusers.fields.address")}
                        name="address"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label={t("gameusers.fields.isActive.label")}
                        name="isActive"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    label: t(
                                        "gameusers.fields.isActive.active",
                                    ),
                                    value: "Is Active",
                                },
                                {
                                    label: t(
                                        "gameusers.fields.isActive.inactive",
                                    ),
                                    value: "Is Inactive",
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>,

    ];

    return (
        <>
            <Create
                isLoading={queryResult?.isFetching}
                footerButtons={
                    <SaveButton
                        style={{ marginRight: 10 }}
                        {...saveButtonProps}
                    />
                }

            >
                <Form
                    {...formProps}
                    style={{ marginTop: 30 }}
                    layout="vertical"
                    initialValues={{
                        isActive: true,
                    }}
                // form={form}
                >
                    {formList[current]}
                </Form>
            </Create>
        </>
    );
};









