import {
    useTranslate,
    IResourceComponentsProps,
    useShow,
    useNavigation,
    HttpError,
} from "@refinedev/core";

import { List, useTable } from "@refinedev/antd";

import {
    EnvironmentOutlined,
    PhoneOutlined,
    MailOutlined,
    BankOutlined,
    HomeOutlined,
    CarOutlined,
    StarOutlined,
} from "@ant-design/icons";
import {
    Table,
    Card,
    Button,
    Space,
    Row,
    Col,
    Grid,
    Typography,
    Rate,
    Avatar,
} from "antd";

import { ICourier, IGameusers, IOrder, IOrderFilterVariables } from "interfaces";

const { useBreakpoint } = Grid;

export const FunctionShow: React.FC<IResourceComponentsProps> = () => {
    const { xl } = useBreakpoint();
    const { queryResult: gameusersQueryResult } = useShow<IGameusers>();
    const gameusers = gameusersQueryResult.data?.data;

    const { tableProps } = useTable<IOrder, HttpError, IOrderFilterVariables>({
        resource: "gameusers",
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "order.gameusers.id",
                operator: "eq",
                value: gameusers?._id,
            },
        ],
        initialPageSize: 4,
        queryOptions: {
            enabled: gameusers !== undefined,
        },
        syncWithLocation: false,
    });

    const t = useTranslate();
    const { show } = useNavigation();

    return (
        <Row gutter={[16, 16]}>
            <Col xl={6} lg={24} xs={24}>
                <Card bordered={false} style={{ height: "100%" }}>
                    <Space
                        direction="vertical"
                        style={{ width: "100%", height: "100%" }}
                    >
                        <Space
                            direction="vertical"
                            style={{ textAlign: "center", width: "100%" }}
                        >
                            <Avatar
                                size={120}
                                src={gameusers?.avatar?.[0].url}
                            ></Avatar>
                            <Typography.Title level={3}>
                                {gameusers?.name} {gameusers?.surname}
                            </Typography.Title>
                        </Space>
                        <Space
                            direction="vertical"
                            style={{
                                width: "100%",
                                textAlign: xl ? "unset" : "center",
                            }}
                        >
                    
                            <Typography.Text>
                                <PhoneOutlined /> {gameusers?.mobile}
                            </Typography.Text>
                            <Typography.Text>
                                <MailOutlined /> {gameusers?.email}
                            </Typography.Text>
                            <Typography.Text>
                                <HomeOutlined /> {gameusers?.address}
                            </Typography.Text>
                        </Space>
                    </Space>
                </Card>
            </Col>
            <Col xl={18} xs={24}>
                <List
                    title={t("gameusers.gameusers")}
                    headerProps={{
                        extra: <></>,
                    }}
                >
                    <Table {...tableProps} rowKey="id">
                        <Table.Column
                            dataIndex={["gameusers", "id"]}
                            title={t("gameusers.fields.id")}
                            render={(value) => (
                                <Button
                                    onClick={() => {
                                        show("gameusers", value);
                                    }}
                                    type="text"
                                >
                                    #{value}
                                </Button>
                            )}
                        />
                        <Table.Column
                            dataIndex="mobile"
                            title={t("gameusers.fields.mobile")}
                        />
                        <Table.Column
                            dataIndex="email"
                            title={t("gameusers.fields.email")}
                        />
                         <Table.Column
                            dataIndex="address"
                            title={t("gameusers.fields.address")}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
